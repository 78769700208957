<template>
  <div class="team-members">
    <h2 class="team-members__title">Участники команды</h2>
    <ul class="team-members__list">
      <li
        v-for="(member, i) of members"
        :key="i"
        class="team-members__list-item"
        :class="{ captain: member.isCapitan }"
      >
        <img
          class="team-members__list-item-img"
          :src="getSrc(member.photo)"
          width="40"
          height="40"
          alt="team-member"
        />
        <span class="team-members__list-item-name">
          {{ member.fullName }}
        </span>
        <span class="team-members__list-item-status">капитан</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getBaseURL } from "@/utils";

/**
 * @typedef Member
 * @prop {string} fullName
 * @prop {string} isCapitan
 * @prop {string | null} photo
 */

export default {
  props: {
    /**
     * @type {import('vue').PropType<Member[]>}
     */
    members: Array,
  },
  methods: {
    /**
     * @param {string | null} photo
     */
    getSrc(photo) {
      if (photo) return getBaseURL() + photo;
      return require("@/assets/img/pic2/avatar-default.svg");
    },
  },
};
</script>

<style lang="scss" scoped>
.team-members {
  padding: 26px 24px 39.34px 42.54px;
  background-image: url(~@/assets/img/pics/legend-bg-2.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 760px;
  // .team-members__title
  &__title {
    margin: 0px 0px 28px 0px;
    font-size: 32px;
    line-height: 37.02px;
    font-weight: 700;
    color: #fff;
  }
  // .team-members__list
  &__list {
    display: grid;
    align-content: flex-start;
    row-gap: 8px;
    list-style: none;
    margin: 0px;
    padding: 0 16px 0 0;
    @include scrollbar;
    overflow: auto;
    height: calc(100% - 90px);
  }
  // .team-members__list-item
  &__list-item {
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 68px;
    padding: 0 16px;
    background: #00fffe26;

    &.captain {
      .team-members__list-item-img {
        border: 2px solid #ff5050;
      }
      .team-members__list-item-status {
        display: block;
      }
    }
  }
  // .team-members__list-item-img
  &__list-item-img {
    flex: 0 0 40px;
    border-radius: 50%;
  }
  // .team-members__list-item-name
  &__list-item-name {
    flex: 1 1 auto;
    font-size: 18px;
    line-height: 26.03px;
    color: #fff;
  }
  // .team-members__list-item-status
  &__list-item-status {
    display: none;
    font-size: 14px;
    line-height: 20.25px;
    color: #ade5d9;
  }
}
</style>
