<template>
  <div class="team-journal">
    <h2 class="team-journal__title">Журнал активностей</h2>
    <div class="team-journal__filters">
      <button
        v-for="filter of filters"
        :key="filter.type"
        class="button"
        :class="activeFilter === filter.type ? 'button-default' : 'button-fill'"
        @click="changeActiveFilter(filter.type)"
      >
        {{ filter.name }}
      </button>
    </div>
    <ul v-if="filteredActivities.length" class="team-journal__list">
      <li
        v-for="(activity, i) of filteredActivities"
        :key="i"
        class="team-journal__list-item journal-item"
      >
        <div class="journal-item__head">
          <span class="journal-item__type">
            {{ activity.type | activityTypeFilter }}
          </span>
          <TaskStatus :status="activity.status" />
        </div>
        <h3 class="journal-item__title">{{ activity.title }}</h3>
        <div class="journal-item__reward">
          Награда:&nbsp;
          <Coins :topReward="activity.topReward" :botReward="0" :size="14" />
        </div>
        <div class="journal-item__footer">
          <div class="journal-item__dates">
            Срок проведения:
            <span>
              {{ activity.startDate | formatDate2 }} -
              {{ activity.endDate | formatDate2 }}
            </span>
          </div>
          <router-link
            :to="{
              name: filters[activity.type + 1].routeName,
              query: { id: activity.id },
            }"
            class="journal-item__link"
          >
            Перейти
          </router-link>
        </div>
      </li>
    </ul>
    <h3 v-else class="team-journal__empty">У вашей команды нет активностей</h3>
  </div>
</template>

<script>
import TaskStatus from "@/components/TaskStatus.vue";
import Coins from "@/components/Coins.vue";
import { journalActivityTypes } from "@/utils/journal";

export default {
  components: { TaskStatus, Coins },
  props: {
    /**
     * @type {import("vue").PropType<import("@/utils/journal").JournalActivity[]>}
     */
    activities: Array,
  },
  data() {
    return {
      filters: journalActivityTypes,
      activeFilter: -1,
    };
  },
  methods: {
    /**
     * @param {import("@/utils/journal").JournalActivityType} type
     */
    changeActiveFilter(type) {
      this.activeFilter = type;
    },
  },
  computed: {
    /**
     * Фильтруем активности со статусом 0 (Назначена)
     * @returns {import("@/utils/journal").Activity[]}
     */
    normalizedActivities() {
      return this.activities.filter((a) => a.status !== 0);
    },
    /**
     * Фильтруем по выбранному типу
     * @returns {import("@/utils/journal").Activity[]}
     */
    filteredActivities() {
      if (this.activeFilter === -1) return this.normalizedActivities;
      return this.normalizedActivities.filter(
        (a) => a.type === this.activeFilter
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.team-journal {
  height: 100%;
  padding: 28px 24px 28px 34px;
  background-image: url(~@/assets/img/pics/legend-bg-2.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // .team-journal__title
  &__title {
    margin: 0px 0px 28px 0px;
    font-size: 32px;
    line-height: 37.02px;
    font-weight: 700;
    color: #fff;
  }
  // .team-journal__filters
  &__filters {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    margin-bottom: 30px;
  }
  .team-journal__filters .button {
    min-width: 150px;
    font-weight: 400;
  }
  // .team-journal__list
  &__list {
    display: grid;
    align-items: start;
    row-gap: 20px;
    list-style: none;
    padding: 0 13px 0 0;
    margin: 0;
    @include scrollbar;
    overflow: auto;
    height: 502px;
  }
  // .team-journal__list-item
  &__list-item {
    padding: 20px 24px 24px 30px;
    background: #00fffe26;
  }
}
.journal-item {
  // .journal-item__head
  &__head {
    display: flex;
    justify-content: space-between;
  }
  // .journal-item__type
  &__type {
    font-size: 16px;
    color: #ade5d9;
  }
  // .journal-item__status
  &__status {
  }
  // .journal-item__title
  &__title {
    max-width: 388px;
    margin: 0px 0px 24px 0px;
    font-size: 24px;
    line-height: calc(27.77 / 24 * 100%);
    font-weight: 700;
    color: #fff;
  }
  // .journal-item__reward
  &__reward {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    font-size: 18px;
    line-height: 21px;
    color: #ade5d9;
  }
  .journal-item__reward .coins img {
    margin-top: 3px;
  }
  // .journal-item__footer
  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  // .journal-item__dates
  &__dates {
    font-size: 18px;
    line-height: 21px;
    color: #ade5d9;

    span {
      color: #50ffeb;
    }
  }
  // .journal-item__link
  &__link {
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #50ffeb;
    text-decoration: underline;
  }
}
</style>
