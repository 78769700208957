<template>
  <CommonLayout class="game-item">
    <Loading v-if="isLoading" />
    <template v-else>
      <div class="profile">
        <div class="profile__body">
          <div class="profile__col">
            <div class="profile-photo">
              <img
                class="profile-photo__pic"
                src="@/assets/img/pic2/avatar-team-default.svg"
                width="268"
                height="268"
                alt=""
              />
            </div>
          </div>
          <div class="profile__col">
            <h1 class="profile__title">Кабинет команды</h1>
            <div class="profile-info">
              <div class="profile-info__item profile-info__item_size_double">
                <div class="profile-info__label">Название команды</div>
                <div class="profile-info__text">{{ teamProfile.teamName }}</div>
              </div>
              <div class="profile-info__item">
                <div class="profile-info__label">Капитан</div>
                <div class="profile-info__text">
                  {{ teamProfile.captainFIO }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="team-lk-body">
        <div class="team-lk-body__info">
          <TeamTasks :teamProfile="teamProfile" />
          <TeamMembers :members="teamProfile.players" />
        </div>
        <div class="team-lk-body__journal">
          <TeamJournal :activities="activities" />
        </div>
      </div>
    </template>
  </CommonLayout>
</template>

<script>
import { GetTeamActivities, GetTeamProfile } from "@/api/Player";
import Loading from "@/components/Loading.vue";
import TeamJournal from "@/components/team/TeamJournal.vue";
import TeamMembers from "@/components/team/TeamMembers.vue";
import TeamTasks from "@/components/team/TeamTasks.vue";
import CommonLayout from "@/layout/CommonLayout.vue";

export default {
  components: { CommonLayout, TeamTasks, TeamMembers, TeamJournal, Loading },
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      isLoading: false,
      teamProfile: {},
      /**
       * @type {import("@/utils/journal").Activity[]}
       */
      activities: [],
    };
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        const [teamProfile, activities] = await Promise.all([
          GetTeamProfile(),
          GetTeamActivities(),
        ]);
        this.teamProfile = teamProfile.data;
        this.activities = activities.data;
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.team-lk-body {
  position: relative;
  display: grid;
  grid-template-columns: 517px 780px;
  column-gap: 45px;
  margin-bottom: 50px;
  // .team-lk-body__info
  &__info {
    width: 100%;
    display: grid;
    row-gap: 25px;
  }
  // .team-lk-body__journal
  &__journal {
    position: sticky;
    top: 155px;
    width: 100%;
    height: 760px;
  }
}
</style>
