<template>
  <div v-if="reward" class="coins">
    <img
      src="@/assets/img/pic2/coin.svg"
      :width="size"
      :height="size"
      alt="coin"
    />&nbsp;
    <span v-if="isNaN(Number(reward))">
      {{ reward }}
    </span>
    <span v-else>
      {{ reward | priceFilter }}
    </span>
  </div>
  <div v-else class="coins">
    <span v-if="!(typeof topReward === 'number')">-</span>
    <template v-else>
      <img
        src="@/assets/img/pic2/coin.svg"
        :width="size"
        :height="size"
        alt="coin"
      />&nbsp;
      <span>
        <template v-if="typeof botReward === 'number'">
          {{ botReward | priceFilter }} -
        </template>
        {{ topReward | priceFilter }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    topReward: Number,
    botReward: Number,
    reward: String,
    size: Number,
  },
};
</script>

<style lang="scss">
.coins {
  display: flex;
  align-items: center;
  color: #50ffeb;

  img {
    margin-top: 2px;
  }
}
</style>
