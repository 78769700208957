<template>
  <div class="task-status" :class="`task-status_${status}`">
    {{ status | formatTeamQuest }}
  </div>
</template>

<script>
export default {
  props: {
    status: Number,
  },
};
</script>

<style lang="scss">
.task-status {
  font-size: 18px;
  color: #50ffeb;

  &::before,
  &::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    color: #50ffeb;
    background-color: currentColor;
    box-shadow: 0px 0px 6px 0px currentColor;
  }

  &.after {
    &::after {
      content: "";
      margin-left: 8px;
    }
  }
  &.before {
    &::before {
      content: "";
      margin-right: 8px;
    }
  }

  // "Назначена", // Created
  &_0 {
    &::before,
    &::after {
      color: #ffffff;
    }
  }
  // "В работе", // InProgress
  &_1 {
    &::before,
    &::after {
      color: #50ffeb;
    }
  }
  // "На модерации", // OnModeration
  &_2 {
    &::before,
    &::after {
      color: #ffce50;
    }
  }
  // "Выполнена", // Succeeded
  &_3 {
    &::before,
    &::after {
      color: #10f466;
    }
  }
  // "Не выполнена", // Failed
  &_4 {
    &::before,
    &::after {
      color: #ff6161;
    }
  }
}
</style>
