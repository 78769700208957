<template>
  <div class="active-team-task">
    <h2 class="active-team-task__title">
      Количество активных<br />
      командных задач
    </h2>
    <ul class="active-team-task__list">
      <li class="active-team-task__list-item">
        Командных целей&nbsp;<span class="decor"></span>&nbsp;
        <span class="count">{{ teamProfile.activeTeamTargets }}</span>
      </li>
      <li class="active-team-task__list-item">
        Командных тестирований&nbsp;<span class="decor"></span>&nbsp;
        <span class="count">{{ teamProfile.activeTeamTestings }}</span>
      </li>
      <li class="active-team-task__list-item">
        Турнирных таблиц&nbsp;<span class="decor"></span>&nbsp;
        <span class="count">{{ teamProfile.activeTeamTournamentTables }}</span>
      </li>
      <li class="active-team-task__list-item">
        Бонусных задач&nbsp;<span class="decor"></span>&nbsp;
        <span class="count">{{ teamProfile.activeBonusTasks }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    teamProfile: Object,
  },
};
</script>

<style lang="scss" scoped>
.active-team-task {
  padding: 28px 122px 43.5px 41.54px;
  background-image: url(~@/assets/img/pics/legend-bg-2.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 311.29px;

  // .active-team-task__title
  &__title {
    margin: 0px 0px 28px 0px;
    font-size: 32px;
    line-height: 37.02px;
    font-weight: 700;
    color: #fff;
  }
  // .active-team-task__list
  &__list {
    display: grid;
    row-gap: 8px;
    list-style: none;
    margin: 0px 10px 0px 0px;
    padding: 0;
  }
  // .active-team-task__list-item
  &__list-item {
    display: flex;
    align-items: baseline;
    font-size: 18px;
    line-height: normal;
    height: 28px;
    color: #ade5d9;

    span.decor {
      flex: 1 1 auto;
      border-bottom: 1px solid #076259;
    }

    span.count {
      font-size: 24px;
      color: #fff;
    }
  }
}
</style>
